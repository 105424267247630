import React, { useContext } from "react";
import { StateContext } from "../../StateContainer";
import AvatarPink from "../../../img/avatarPink.png";
// import { TbDotsVertical as Dots, TbH1 } from "react-icons/tb";
import Moment from "react-moment";

const MessaggioBot = ({ row, primaryRGBA }) => {
  const { dettagliProgetto } = useContext(StateContext);

  // const linkRegex = /(https?:\/\/[^\s]+)/g;
  const linkRegex = /<iframe[^>]*>.*?<\/iframe>|(https?:\/\/[^\s]+)/gi;

  const mailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  return (
    <li className="pb-5 message-bot">
      <div className="d-flex align-items-start gap-2">
        <div
          style={{
            height: "2.4vw",
            width: "2.4vw",
            minHeight: "16px",
            minWidth: "16px",
            borderRadius: "100%",
            background: `url(${
              dettagliProgetto && dettagliProgetto.chatbotIcon
                ? dettagliProgetto.chatbotIcon
                : AvatarPink
            }) center center / cover no-repeat`,
          }}
          className=""
        ></div>
        <div>
          <h5 className="font-size-14 conversation-name">
            <a href="#" className="text-dark text-decoration-none">
              {dettagliProgetto ? dettagliProgetto.assistantName : ""}
            </a>{" "}
          </h5>

          <div className="conversation-list moveUpAnimation">
            {!row.botMessage.split ? (
              <div className="ctext-wrap m-0 ">
                <div
                  className="ctext-wrap-content m-0 d-flex align-items-center"
                  style={{
                    background: `${primaryRGBA}`,
                    maxWidth: "300px",
                    hyphens: "auto",
                  }}
                >
                  <div className="form-outline">
                    <div className="loading-dots">
                      <div className="loading-dots--dot-conversation"></div>
                      <div className="loading-dots--dot-conversation"></div>
                      <div className="loading-dots--dot-conversation"></div>
                    </div>
                  </div>
                </div>
                {/* <div className='dropdown'>
                  <a
                    className='text-decoration-none text-dark'
                    id='dropdownMenuButton1'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <Dots />
                  </a>
                  <ul
                    className='dropdown-menu align-top'
                    aria-labelledby='dropdownMenuButton1'
                  >
                    <li
                      className='mb-0 dropdown-item '
                      dangerouslySetInnerHTML={{
                        __html: row.botMessage.sources,
                      }}
                    ></li>
                  </ul>
                </div> */}
              </div>
            ) : (
              <div className="">
                {row.botMessage.text
                  .replace(/\n/g, "<br/>") // Sostituisci \n con <br/>
                  .split(/<br\/>|(?<=\.|\?|\!)\s+(?=[A-Z])/) // Spezza il testo in paragrafi utilizzando i punti e la lettera maiuscola come delimitatori
                  .reduce((acc, sentence) => {
                    const lastElement = acc[acc.length - 1];
                    if (
                      !lastElement ||
                      lastElement.length + sentence.length > 250
                    ) {
                      acc.push(sentence + " ");
                    } else {
                      acc[acc.length - 1] += sentence + " ";
                    }
                    return acc;
                  }, [])
                  .map((paragraph, index) => {
                    // Check if the paragraph is not empty
                    if (paragraph.trim().length <= 1) {
                      return null; // Skip rendering empty paragraphs
                    }

                    // Check if the paragraph starts with a period and remove it
                    const formattedParagraph = paragraph.trim().startsWith(".")
                      ? paragraph.trim().slice(1)
                      : paragraph.trim();

                    return (
                      <div
                        className="conversation-list m-0"
                        style={{ maxWidth: "400px" }}
                        key={index}
                      >
                        <div className="ctext-wrap mt-2">
                          <div
                            className="ctext-wrap-content m-0"
                            style={{
                              background: `${primaryRGBA}`,
                              maxWidth: "300px",
                              hyphens: "auto",
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{
                                color: `${
                                  dettagliProgetto
                                    ? dettagliProgetto.userMessageColor
                                    : ""
                                }`,
                              }}
                              dangerouslySetInnerHTML={{
                                __html: formattedParagraph
                                  .trim()
                                  .split(
                                    /(<(?:iframe|a)[^>]*>[\s\S]*?<\/(?:iframe|a)>)/gi
                                  )
                                  // Dividiamo il testo in base ai tag <iframe>
                                  .map((part, idx) => {
                                    if (idx % 2 === 0) {
                                      // Se è un'indice pari, trattiamo il testo al di fuori dei tag <iframe>
                                      return part
                                        .replace(linkRegex, (match) => {
                                          return `<a href="${match}" target="_blank">${match}</a>`;
                                        })
                                        .replace(mailRegex, (match) => {
                                          return `<a href="mailto:${match}" target="_blank">${match}</a>`;
                                        });
                                    } else {
                                      // Se è un'indice dispari, trattiamo direttamente il tag <iframe> senza modifiche
                                      return part;
                                    }
                                  })
                                  .join(""),
                              }}
                            ></p>
                          </div>

                          {/* <div className='dropdown'>
                        <a
                            className='text-decoration-none text-dark'
                            id='dropdownMenuButton1'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                        >
                            <Dots />
                        </a>
                        <ul
                            className='dropdown-menu align-top'
                            aria-labelledby='dropdownMenuButton1'
                        >
                            <li
                            className='mb-0 dropdown-item '
                            dangerouslySetInnerHTML={{
                                __html: row.botMessage.sources
                                .replace(/\\/g, '"')
                                .replace(/"n/g, "<br/>")
                                .replace(/\n/g, "<br/>"),
                            }}
                            ></li>
                        </ul>
                        </div> */}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
            <span className="d-inline-block font-size-12 text-muted">
              <Moment format="HH:mm">{row.botMessage.date}</Moment>
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default MessaggioBot;
